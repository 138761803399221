import React, { useState } from "react";
import phone from "./phone.png";
import call from "./call.svg";
import camera from "./camera.png";
import navImage from "./navImage.png";
import Switch from "react-ios-switch";
import classNames from "classnames";
import "./App.css";

function App() {
  const [blurState, setBlurState] = useState("none");

  /* NORMAL BLUR FUNCTION*/
  function toggleNormalBlur() {
    setBlurState((prevState) => (prevState !== "normal" ? "normal" : "none"));
  }

  /* DRAMATIC BLUR FUNCTION*/
  function toggleDramaticBlur() {
    setBlurState((prevState) =>
      prevState !== "dramatic" ? "dramatic" : "none"
    );
  }

  return (
    <div className="App">
      <div className="blurDemo">
        <div className="phoneContainer">
          <img draggable="false" className="phone" src={phone} />
          <div
            className={classNames(
              "maskBlur",
              blurState !== "none" && blurState
            )}
          />
          <img draggable="false" className="call" src={call} />
          <img draggable="false" className="camera" src={camera} />
          <img draggable="false" className="navImage" src={navImage} />
        </div>
        <div
          className={classNames(
            "toggleBlurContainer",
            blurState === "normal" && "active"
          )}
        >
          <span>Normal Blur</span>
          <Switch
            checked={blurState === "normal"}
            onChange={toggleNormalBlur}
            onColor="#FC55AC"
          />
        </div>
        <div
          className={classNames(
            "toggleBlurContainer",
            blurState === "dramatic" && "active"
          )}
        >
          <span>Dramatic Blur</span>
          <Switch
            checked={blurState === "dramatic"}
            onChange={toggleDramaticBlur}
            onColor="#FC55AC"
          />
        </div>
      </div>
    </div>
  );
}

export default App;
